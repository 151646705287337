.header {

    background-color: white;
    height: auto;
    padding: $space-med;

    @include tablet-portrait-up {
        height: 15rem;
        padding: $space-lrg;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    &__logo {

        width: 100%;
        height: auto;

        @include mobile {
            margin-bottom: $space-med;
        }

        @include tablet-portrait-up {
            width: 30rem;
            height: auto;
        }
        
    }
    
}