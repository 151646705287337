.group-map {
    position: relative;

    @include mobile {
        #svg-map {
            display: none;
        }
    }



    &__textbox {
        padding: $space-med;
        color: $inverse-01;

        @include tablet-portrait-up {
            padding: $space-lrg;
            position: absolute;
            top: 0;
            left: 0;
            max-width: 60vw;
        }

        &__main-heading {
            @include desktop-up {
                font-size: 5.5rem;
            }
        }
        &__description {
            font-size: 2.2rem;
        }


        &__ll-icon {
            width: 5rem;
            height: 5rem;
            margin-top: 2rem;
    
            @include tablet-portrait-up {
                width: 7rem;
                height: 7rem;
            }
        }
        &__ll-heading {
            font-weight: 500;
        }

    }

}


// inline svg

svg {
    display: block;
    width: 100%;
}


// svg map elements

.pin {
    cursor: pointer;
    transition: all 350ms ease;
    fill: $brand-02 !important;

    &:hover {
        fill: darken($brand-02, 20%) !important;
    }
}

#hk-text, #kl-text, #sg-text {
    fill: white !important;
}

.hot { fill: black !important; }