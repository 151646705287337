.modal {
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    &.is-visible {
        visibility: visible;
    }

    &__overlay {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: hsla(0, 0%, 0%, 0.603);
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 0.3s, opacity 0.3s;
    }

    &.is-visible &__overlay {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }

    &__wrapper {
        z-index: 9999;
        background-color: #fff;
        box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
    }

    &--transition {
        transition: all 0.3s 0.12s;
        transform: translateY(-10%);
        opacity: 0;
    }

    &.is-visible &--transition {
        transform: translateY(0);
        opacity: 1;
    }


    // display normally on mobile (no modal)

    @include mobile {
        position: relative;
        

        &__overlay {
            display: none;
            
        }
        &__wrapper {
            visibility: visible;
            opacity: 1;
            padding: 0 $space-med $space-med;
            background-color: transparent;
            box-shadow: none;
        }
        &--transition {
            transform: translateY(0);
        }
    }
    
}