.button {

    display: inline-block;
    background-color: $default-button-col;
    padding: 1rem 2.5rem;
    border-radius: $global-button-radius;

    color: white;
    text-align: center;
    text-decoration: none;

    transition: all 300ms ease;

    &:hover {
        background-color: darken($default-button-col, 15%);
    }

    @include mobile {
        display: block;
    }

    &--light-grey {
        background-color: $ui-01;
        color: $text-01;
        &:hover {
            background-color: darken($ui-01, 7.5%);
        }
    }

}