*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
body{
  box-sizing: border-box;
}
