* {
    font-family: $font-sans;
}

body {
    font-size: $default-font-size;
    font-weight: $default-font-weight;
}



p, ul, ol {
    margin-bottom: 1rem;
    line-height: $default-line-height;
    font-weight: $default-font-weight;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}

h1 {
    font-size: 4.8rem;
    margin-bottom: 2rem;
    @include mobile { font-size: 3.2rem; }
}
h2 {
    font-size: 3.6rem;
    margin-bottom: 1.8rem;
    @include mobile { font-size: 2.6rem; }
}
h3 {
    font-size: 2.8rem;
    margin-bottom: 1.6rem;
    @include mobile { font-size: 2.2rem; }
}
h4 {
    font-size: 2.1rem;
    margin-bottom: 1.4rem;
}
h5 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
}
h6 {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
}

a {
    color: black;
}