.card {
    position: relative;
    background-color: white;

    // display logic

    @include mobile {
        &:not(:last-child) {
            margin-bottom: $space-med;
        }
    }

    @include tablet-portrait-up {
        visibility: hidden;
        display: none;
        max-width: 35rem;
        flex-direction: column;

        &.selected {
            visibility: visible;
            display: flex;
        }
    }
    @include tablet-landscape-up {
        max-width: 70rem;
        flex-direction: row;
    }
    
    // layout

    &__hero {
        @include tablet-portrait-up {
            flex: 1;
        }

        &__img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        
    }

    &__content {
        padding: $space-med;
        text-align: center;
        @include tablet-portrait-up {
            flex: 1;
        }

        &__title {

        }
    
        &__text {
            margin-bottom: 2rem;
            font-size: 1.6rem;
        }

    }

    &__close-btn {
        position: absolute;
        top: $close-btn-size/-2;
        right: $close-btn-size/-2;
        width: $close-btn-size;
        height: $close-btn-size;
        border-radius: $close-btn-size;
        background-color: $ui-02;
        text-decoration: none;
        transition: all 350ms ease;

        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: '\02715';
            font-size: 1.6rem;
            color: white;
        }

        &:hover {
            background-color: darken($ui-02, 15%);
        }

        @include mobile { display: none; }
    }

    
}
