$font-sans: 'Montserrat', 'Helvetica Neue', sans-serif;

// fonts
$default-font-size: 1.8rem;
$default-font-weight: 300;
$default-line-height: 1.3;

// colours
$brand-01: #00B6BA; //aqua
$brand-02: #DB4034; //red
$brand-03: #FE691D; //orange
$brand-04: #FDDC00; //yellow
$brand-05: #AAAAAA; //grey
$brand-06: #DADADA; //light-grey

$ui-01: #EBEBEB;
$ui-02: #aaaaaa;
$ui-03: #fff;
$ui-04: #fff;
$ui-05: #fff;

$text-01: #595959;
$text-02: #414141;
$text-03: #fff;

$inverse-01: #fff;

$field-01: #fff;

$support-01: #fff;
$support-02: #fff;
$support-03: #fff;
$support-04: #fff;

// layout
$site-max-width: 144rem;
$space-lrg: 6rem;
$space-med: 3rem;
$space-sml: 1rem;

$header-height: 15rem;
$header-height-mob: 20rem;

$global-button-radius: 5rem;

// buttons
$default-button-col: $brand-02;
$close-btn-size: 4rem;