.overlay {
    
    padding: $space-med;

    @include tablet-portrait-up {
        display: none;

        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: rgba(42, 0, 45, 0);

        &.open {
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: rgba(42, 0, 45, .75);
        }
        
    }
}